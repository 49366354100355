import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'
import { makeStyles } from '@material-ui/core/styles'
import SectionStyle1 from '../../components/curves/SectionStyle1'

import InnerLink from '../../components/Typography/InnerLink'
const hasirTelKullanim1 = [
  'Kuş,tavuk ve hayvan kafeslerin yapımında',
  'Reklam pano ve standlarda',
  'Elektrik kablosu taşıma kanallarında',
  'Asansör boşuklarında ve etrafında',
  'Süpermarket,depo ve istifleme raflarında',
  'Çiçek seralarında',
  'Fabrika gibi alanlarda taşıma sepeti olarak',
  'Futbol , basketbol,tenis vb sahalarda',
  'Sanayi tipi kaba eleklerde',
  'Metal mutfak araç ve gereçlerinde',
  'Bahçe duvarlarında çit ve korkuluk olarak',
  'Özel kimyasal yıkama makinelerinde',
  'Gıda ile ilgili sepetlerde',
  'Tıbbi ürünlerde',
  'Deniz suyunun temas ettiği yerlerde',
  'Kimyasal yıkama fabrikalarında',
]
const hasirTelKullanim2 = [
  'Balık fabrikalarında',
  'Tenis Kortları',
  'Basketbol Sahaları',
  'Çeşitli Spor Alanları',
  'Depo',
  'Market',
  'Mağaza Raf Sistemleri',
  'Tarım',
  'Ulaşım ve Yük Araçları',
  'Kaba Elek İmalatında',
  'Sehpa, Masa, Sandalye ve Süs Eşyaları',
  'İnşaat sıva altlarında',
  'Güvenlik Çitleri',
  'Bahçe Duvarı Korkulukları',
  'Asansör Boşlukları',
  'İnşaat Temelleri',
]
const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingTop: '1px',
    paddingBottom: '1px',
    '& .MuiListItemText-primary': {
      [theme.breakpoints.up('xl')]: {
        fontSize: '1.5em',
      },
    },
  },
  listItemIcon: {
    minWidth: '35px',
    color: theme.palette.success.main,
  },
  listHeader: {
    marginBottom: theme.spacing(4),
    fontWeight: 500,
    [theme.breakpoints.up('xl')]: {
      fontSize: '3em',
    },
  },
}))
const HasirTelKullanim = ({ title, transparentBG }) => {
  const classes = useStyles()
  return (
    <>
      <SectionStyle1
        bgColor={transparentBG ? 'transparent' : 'rgb(237, 247, 237)'}
        hasBottom={transparentBG ? false : true}
        hasTop={transparentBG ? false : true}
      >
        <Typography className={classes.listHeader} component='h2' variant='h4' align='center' color='textPrimary' gutterBottom>
          {title || 'Paslanmaz Hasır Tel Kullanım Alanları'}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <List aria-label='Hasır Tel Kullanım Alanları'>
              {hasirTelKullanim1.map((item, index) => (
                <ListItem key={`${item}-${index}`} className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <List aria-label='Hasır Tel Kullanım Alanları'>
              {hasirTelKullanim2.map((item, index) => (
                <ListItem key={`${item}-${index}`} className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
        <Box textAlign='center' mt={3}>
          <InnerLink title='İletişim Bilgilerimiz İçin Tıklayınız' url='/iletisim' color='primary' />
        </Box>
      </SectionStyle1>
    </>
  )
}
export default HasirTelKullanim
