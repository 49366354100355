import React from 'react'
import Box from '@material-ui/core/Box'
const ImgWrapper = ({ onClick, children }) => {
  return (
    <Box onClick={onClick && onClick} style={onClick && { cursor: 'pointer' }}>
      {children}
    </Box>
  )
}
export default ImgWrapper
