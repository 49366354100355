import React, { useRef } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useStore from '../store/useStore'
import ContentHeader from '../components/Typography/ContentHeader'
import ContentText from '../components/Typography/ContentText'
import InnerLink from '../components/Typography/InnerLink'
import MediumImage from '../components/Image/MediumImage'
// import SectionStyle1 from '../components/curves/SectionStyle1'
import SEO from '../layouts/Seo'
import ImgWrapper from '../components/Image/ImgWrapper'
import ImgLightBox from '../components/lightbox/ImgLightBox'
import GeneralCarousel from '../contents/carousel/GeneralCarousel'
import KareGozluTablo from '../contents/tables/KareGozluTablo'
import DikdortgenGozluTablo from '../contents/tables/DikdortgenGozluTablo'
import OlcuAlert from '../contents/alert/OlcuAlert'
import HasirTelKullanim from '../contents/common/HasirtelKullanim'
const CelikHasirTelPage = () => {
  const { getImageByName } = useStore()
  const lightBoxRef = useRef()
  return (
    <>
      <SEO
        title='Çelik Hasır Tel İmalatı - Erez Kaynak'
        description='Firmamız siyah ham çelik tel malzeme kullanarak punta kaynaklı çelik hasır tel imalatı yapmaktadır. Çelik hasır teller ham malzeme olduğu için sonradan fırın boya yada daldırma boya ile boyanabilir. Tel kalınlığı 2mm den 8mm tel kalınlığına kadar çelik hasır tel imalatını yapabiliyoruz.'
        keywords='çelik hasır,çelik hasır tel,hasır tel,çelik tel hasır,çelik kafes tel,çelik tel,çelik hasır tel imalatı,çelik hasır tel fiyatları,hasır tel fiyatları,çelik kafes tel fiyatları,çelik çesan,çelik kafes hasır,çelik fens teli,çelik hasır tel istanbul,çelik hasır tel kullanım alanları'
        url='celik-hasir-tel-imalati'
        imgurl={getImageByName('hasirtel11').sm.src}
      />
      <ImgLightBox ref={lightBoxRef} gallery={[getImageByName('hasirtel11').original.src]} />
      <Box paddingX={3} paddingY={5}>
        <Grid container spacing={6} alignItems='flex-start'>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
            <ContentHeader title='Çelik Hasır Tel İmalatı' description='Türkiyenin her yerine gönderim yapılmaktadır.' />
            <ContentText>
              <p>
                Firmamız <strong>siyah ham çelik tel</strong> malzeme kullanarak <strong>punta kaynaklı çelik hasır tel</strong> imalatı
                yapmaktadır.
              </p>
              <p>
                <strong>Çelik hasır teller</strong> ham malzeme olduğu için sonradan fırın boya ya da daldırma boya ile boyanabilir.
              </p>
              <p>
                Tel kalınlığı 2mm den 8mm tel kalınlığına kadar <strong>çelik hasır tel</strong> imalatını yapabiliyoruz.
              </p>
              <p>
                Göz aralığı standart olarak merkezden merkeze 15mm, 20mm, 25mm ve katlarında <strong>çelik hasır tel</strong> imalatı
                yapılabilir.
              </p>
              <p>
                15mm katı olan 15x90mm veya 25mm katı olan 50x125mm gibi istediğiniz her ölçüde <strong>çelik hasır tel</strong>{' '}
                yapabilmekteyiz.
              </p>
              <p>
                Özel göz aralıklarında (örn; 26,3×37,5mm vb.) yeni kalıp yaparak hızlı bir şekilde müşterilerimizin ihtiyacı olan{' '}
                <strong>çelik hasır telleri</strong> uygun bir maliyet ile üretiyoruz.
              </p>
              <p>Detaylı bilgi yada fiyat teklifi almak için bizi arayınız.</p>
            </ContentText>
            <Grid container justify='center' spacing={3}>
              <Grid item>
                <InnerLink title='Nervürlü Çelik Hasır Tel İmalatı' url='/nervurlu-celik-hasir-tel-imalati' />
              </Grid>
              <Grid item>
                <InnerLink title='İletişim Bilgilerimiz İçin Tıklayınız' url='/iletisim' />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
            <ImgWrapper onClick={() => lightBoxRef.current.openLightBox()}>
              <MediumImage lazyHeight={500} img={getImageByName('hasirtel11')} alt='Özel hasır tel imalatı' />
            </ImgWrapper>
            <Box mt={2}>
              <MediumImage lazyHeight={500} img={getImageByName('celikhasirolculeri')} alt='özel göz hasır tel imalatı erez kaynak' />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <GeneralCarousel />

      <HasirTelKullanim title='Çelik Hasır Teli Hasır Tel Kullanım Alanları' />

      <Box p={3}>
        <OlcuAlert />
      </Box>
      <Box p={3} mb={5}>
        <KareGozluTablo />
      </Box>
      <Box p={3} mb={5}>
        <DikdortgenGozluTablo />
      </Box>
    </>
  )
}

export default CelikHasirTelPage
